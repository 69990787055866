<template>
  <div>
    <MDBRow class="pb-4">
      <MDBCol md="12" xl="8" cols="12">
        <div class="bg-white px-4 py-3">
          <h5 class="pb-2">{{ $t("serviceNumber.subtitle") }}</h5>

          <MDBRow>
            <MDBCol md="6" cols="12">
              <div class="d-flex flex-column justify-content-end h-100">
                <div class="sub-title-contact pt-2">
                  {{ $t("serviceNumber.sales_manager") }}
                </div>
                <h5 class="pb-2">Melvin Caldenhove</h5>
                <div>
                  <span class="fw-bold">E-Mail</span>&nbsp;
                  <a href="mailto:melvin.caldenhove@bridgestone.eu">Melvin.caldenhove@bridgestone.eu
                  </a>
                </div>
                <div>
                  <b>{{ $t("profile.phone") }}:&nbsp;</b>
                  06-23965538
                </div>

                <div class="d-grid gap-2 pt-4">
                  <MDBBtn type="button" color="danger" class="mt-4"
                    @click="openEmail('melvin.caldenhove@bridgestone.eu')">{{ $t("buttons.makeContact") }}</MDBBtn>
                </div>
              </div>
            </MDBCol>
            <MDBCol md="6" cols="12" class="d-flex justify-content-end">
              <img class="cto" src="@/assets/img/contact/sellers_nl/melvin_caldenhove.jpg" alt="" />
            </MDBCol>
          </MDBRow>
        </div>
      </MDBCol>
      <MDBCol md="12" xl="4" cols="12">
        <div class="bg-white px-4 py-3 h-100">
          <h5 class="pb-2 font-20 font-lg-20 font-xl-25">
            BRIDGESTONE
            <span class="fw-bold text-danger text-uppercase">PLUS</span>
            klantenservice
          </h5>
          <div class="pb-3">
            <div class="fw-bold text-danger text-uppercase">
              Algemeen e-mailadres
            </div>
            <span class="fw-bold">E-mail:</span>&nbsp;<a href="mailto:plus-nl@bridgestone.eu">plus-nl@bridgestone.eu</a>
          </div>
          <div class="pb-3">
            Voor vragen/opmerkingen over het platform of wijzigingen aan uw
            account.
          </div>
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow>
      <MDBCol cols="12" lg="6" xl="4" class="mb-4" v-for="(data, i) in salesmenDataNl" :key="i">
        <Card :data="data" :linkText="data.linkText" :simpleLink="true" target="_blank" class="h-100" />
      </MDBCol>
    </MDBRow>
  </div>
</template>

<script setup>
import { MDBRow, MDBCol, MDBBtn } from "mdb-vue-ui-kit";
import Card from "@/components/General/Card/index.vue";

import i18n from "@/plugins/i18n";

const openEmail = (email) => {
  window.open(`mailto:${email}`);
};

const salesmenDataNl = [
  {
    name: `<div class="font-12 font-xxl-16 text-danger text-uppercase">${i18n.global.t(
      "serviceNumber.sales_representative"
    )}</div>Marc Pannekoek`,
    link: "mailto:Marc.pannekoek@bridgestone.eu",
    description: `<span class="font-12 font-xxl-16"><b>E-Mail:</b> Marc.pannekoek@bridgestone.eu<br/>
<b>${i18n.global.t("profile.phone")}:</b> 06-53691496</span>`,
    image: require("@/assets/img/contact/sellers_nl/marc_pannekoek.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
  {
    name: `<div class="font-12 font-xxl-16 text-danger text-uppercase">${i18n.global.t(
      "serviceNumber.key_account"
    )}</div>Jordy Grimm`,
    link: "mailto:Jordy.grimm@bridgestone.eu",
    description: `<span class="font-12 font-xxl-16"><b>E-Mail:</b> Jordy.grimm@bridgestone.eu<br/>
<b>${i18n.global.t("profile.phone")}:</b> 06-15500920</span>`,
    image: require("@/assets/img/contact/sellers_nl/jordy_grimm.png"),
    linkText: i18n.global.t("buttons.makeContact"),
  },
];
</script>

<style scoped lang="scss">
@import "@/styles/contact";
</style>
