<template>
  <MDBRow style="margin-top: -110px">
    <MDBCol cols="12" lg="8" xl="8">
      <h2
        v-if="points >= 0 && view !== false"
        class="success-green font-18 font-md-25 fw-bold"
      >
        {{ $t("thankyou.form_points.survey_completed") }}
      </h2>
      <p v-if="points > 0 && view !== false">
        {{ $t("thankyou.form_points.description") }}
        <br /><br />
        {{ $t("thankyou.form_points.description2")
        }}<b class="success-green">{{ points }}</b
        >{{ $t("thankyou.form_points.description3") }}
      </p>
      <p v-if="points === 0 && view !== false">
        {{ $t("thankyou.form_only.description") }}
      </p>
      <p v-if="points === -1 && view != false">
        {{ $t("thankyou.form_only.error") }}<b>{{ message_error }}</b>
      </p>
      <p v-if="points === -2 && view != false">
        <b>{{ message_error }}</b>
      </p>
    </MDBCol>
  </MDBRow>
  <MDBRow>
    <MDBCol cols="6" lg="6" xl="6" class="mb-6">
      <button class="btn btn-danger mt-4" @click="goToHome()">
        {{ $t("thankyou.index.go_to_home") }}
      </button>
      <button class="btn btn-danger mt-4" @click="goToAccount()">
        {{ $t("thankyou.index.go_to_account") }}
      </button>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { useStore } from "vuex";
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";

const store = useStore();
const router = useRouter();
const points = computed(() => store.getters.getPointsFromSurvey);
const message_error = computed(() => store.getters.getMessageError);
const view = ref(false);

onMounted(async () => {
  let hcvisitor = localStorage.getItem("hcvisitor");
  const data = {
    hcvisitor: hcvisitor,
  };
  await store.dispatch("setPointsFromSurvey", data);
  localStorage.setItem("hcvisitor", null);
  view.value = true;
  await store.dispatch("me");
});

const goToHome = () => {
  router.push({ name: "dashboard" });
};
const goToAccount = () => {
  router.push({ name: "account" });
};
</script>
