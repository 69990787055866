<template>
  <MDBRow>
    <MDBCol>
      <h3 class="my-3 text-uppercase">
        {{ $t("contact.form.tires") }} {{ props.id }}
      </h3>
    </MDBCol>
  </MDBRow>
  <MDBRow>
    <MDBCol col="6" v-if="tyrePositions.length > 0">
      <Field :name="'name' + id" as="select" class="form-select form-select-lg mb-3"
        aria-label=".form-select-lg example" v-model="data.tyre_position_id" :rules="{ required: props.required }"
        :label="$t('contact.form.tirePosition')">
        <option selected value="">
          {{ $t("contact.form.tirePosition") }}{{ props.required ? "*" : "" }}
        </option>
        <option :value="0">{{ $t("contact.form.notKnown") }}</option>
        <template v-for="tyrePosition in tyrePositions" :key="tyrePosition.id">
          <option disabled>{{ tyrePosition.name }}</option>
          <template v-if="tyrePosition.children.length > 0">
            <option :value="child.id" v-for="child in tyrePosition.children" :key="child.id">
              {{ child.name[LANG] }}
            </option>
          </template>
        </template>
      </Field>
      <div class="w-100">
        <ErrorMessage :name="'name' + id" class="text-danger text-small text-end m-0 pt-1" />
      </div>
    </MDBCol>
    <MDBCol col="6">
      <div class="input-group mb-3">
        <Field type="text" class="form-control" :placeholder="setLabel($t('tireSizeExample'))"
          :aria-label="setLabel($t('tireSizeExample'))" aria-describedby="basic-addon1" v-model="data.size"
          :name="'size' + id" :rules="{ required: props.required }" :label="$t('tireSizeExample')" />
        <div class="w-100">
          <ErrorMessage :name="'size' + id" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </div>
    </MDBCol>
    <MDBCol col="6">
      <div class="input-group mb-3">
        <Field type="text" class="form-control" :placeholder="setLabel($t('speedLoadIndex'))"
          :aria-label="setLabel($t('speedLoadIndex'))" aria-describedby="basic-addon1" v-model="data.index"
          :name="'index' + id" :rules="{ required: props.required }" :label="$t('speedLoadIndex')" />
        <div class="w-100">
          <ErrorMessage :name="'index' + id" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </div>
    </MDBCol>
    <MDBCol col="6">
      <div class="input-group mb-3">
        <Field type="text" class="form-control" :placeholder="setLabel($t('profileDesignation'))"
          :aria-label="setLabel($t('profileDesignation'))" aria-describedby="basic-addon1" v-model="data.profile"
          :name="'profile' + id" :rules="{ required: props.required }" :label="$t('profileDesignation')" />
        <div class="w-100">
          <ErrorMessage :name="'profile' + id" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </div>
    </MDBCol>
    <MDBCol col="6">
      <div class="input-group mb-3">
        <Field type="text" class="form-control" :placeholder="setLabel($t('contact.form.DOT'))"
          :aria-label="setLabel($t('contact.form.DOT'))" aria-describedby="basic-addon1" v-model="data.dot"
          :name="'dot' + id" :rules="{ required: props.required, max: 11 }" :label="$t('contact.form.DOT')" />
        <div class="w-100">
          <ErrorMessage :name="'dot' + id" class="text-danger text-small text-end m-0 pt-1" />
        </div>
      </div>
    </MDBCol>
    <MDBCol col="6">
      <div class="input-group mb-3">
        <Field type="text" class="form-control" :placeholder="setLabel($t('contact.form.TruckSerialNo'))"
          :aria-label="setLabel($t('contact.form.TruckSerialNo'))" aria-describedby="basic-addon1"
          v-model="data.serial_number" :name="'serial_number' + id" :label="$t('contact.form.TruckSerialNo')" />
      </div>
    </MDBCol>
  </MDBRow>
</template>

<script setup>
import { MDBRow, MDBCol } from "mdb-vue-ui-kit";
import { defineProps, ref, computed } from "vue";
import { useStore } from "vuex";
import { Field, ErrorMessage } from "vee-validate";
const LANG = localStorage.getItem("lang");

const props = defineProps({
  id: Number,
  values: Object,
  required: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();
const tyrePositions = computed(() => store.getters.getTyrePositions);

const data = computed(() => {
  return props.values
    ? props.values
    : ref({
      tyre_position_id: null,
      size: "",
      index: "",
      profile: "",
      dot: "",
      serial_number: "",
    });
});

const setLabel = (text) => {
  return props.required ? `${text}*` : `${text}`;
};
</script>

<style scoped lang="scss">
.form {

  .form-control,
  .form-select {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1rem;
    border-radius: 0.3rem;
    height: auto;
  }

  select {
    option:first-child {
      display: none;
    }
  }
}
</style>
