<template>
  <MDBCard v-if="isDutch && canSeeAccountStatement" class="score-container mb-3 mt-3 mb-lg-0 h-100" :class="[
    {
      intro__step: user?.commitment_letter_accept && user?.intro == 3,
    },
  ]">
    <MDBCardBody>
      <MDBCardTitle class="title title-slash text-uppercase font-12 font-md-25">
        {{ $t("kickback.title") }}
      </MDBCardTitle>
      <MDBCardText>
        <p class="mb-0 font-xxxl-18 font-11 fw-bold">
          {{ $t("dashboard.bankstatement.yourKickback") }}
        </p>
        <h2 class="success-green font-18 font-md-25 fw-bold">
          {{ user?.commitment_letter_accept && user.display_kickbacks ? user.kick_back_fees ?? 0 : 0
          }} Euro
        </h2>
        <p class="text-small">
          {{ $t("dashboard.bankstatement.lastUpdate") }}
          {{
            lastUpdate?.date
              ? dates.parseDate(lastUpdate?.date)
              : dates.parseDate(new Date())
          }}
        </p>
        <DashboardLink :to="{ name: 'pointsOverview' }" :disabled="(user?.commitment_letter_accept && user?.intro == 2) ||
          isStaff ||
          !canSeeAccountStatement
          ">
          {{ $t("dashboard.bankstatement.kickbackLink") }}
        </DashboardLink>
      </MDBCardText>
    </MDBCardBody>
  </MDBCard>
</template>

<script setup>
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
} from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import DashboardLink from "@/components/Dashboard/Dashboard-link.vue";
import { ref, watch, computed, onMounted } from "vue";
import dates from "@/plugins/dates";
import TokenService from "@/services/storage.service";

const LANG = localStorage.getItem("lang");
const isDutch = computed(() => LANG === "nl");
const store = useStore();

const user = computed(() => store.getters.getMe);
const isStaff = ref(false);
const canSeeAccountStatement = ref(false);

watch(user, async (newVal) => {
  if (newVal && newVal.roles.find((item) => item.name == "staff")) {
    isStaff.value = true;
  }
  canSeeAccountStatement.value = TokenService.showElement("kickBackFeeBox.see");
});

const lastUpdate = computed(() => store.getters.getLastBankStatement);

onMounted(() => {
  canSeeAccountStatement.value = TokenService.showElement(
    "accountStatement.see"
  );
})
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";

.score-container {
  max-height: 206px;
}
</style>
