import ProductInfo from "@/components/ProductInfo/index.vue";
import ProductInfoContent from "@/components/ProductInfo/Content/List.vue";
import Default from "../views/Default.vue";
import SubDefault from "../views/SubDefault.vue";
import Pkw from "@/components/ProductInfo/Preislisten/Pkw.vue";
import PkwList from "@/components/ProductInfo/Preislisten/List/Pkw.vue";
import Produktbroschuren from "@/components/ProductInfo/Produktbroschuren/index.vue";
import ProduktbroschurenList from "@/components/ProductInfo/Produktbroschuren/Content/List.vue";
import BridgestoneProduktbroschuren from "@/components/ProductInfo/Produktbroschuren/BridgestoneProduktbroschuren.vue";
import BridgestoneList from "@/components/ProductInfo/Produktbroschuren/Content/BridgestoneList.vue";
import FirestoneList from "@/components/ProductInfo/Produktbroschuren/Content/FirestoneList.vue";
import FirestoneProduktbroschuren from "@/components/ProductInfo/Produktbroschuren/FirestoneProduktbroschuren.vue";
import Technologien from "@/components/ProductInfo/Produktbroschuren/Technologien.vue";
import TechnologyList from "@/components/ProductInfo/Produktbroschuren/Content/TechnologyList.vue";
import Pricat from "@/components/ProductInfo/Pricat.vue";
import PricatList from "@/components/ProductInfo/Preislisten/List/PricatList.vue";
import BDrive from "@/components/ProductInfo/BDrive.vue";
import BdriveContent from "@/components/ProductInfo/Produktbroschuren/Content/BdriveContent.vue";
import ProductInfoAndPriceList from "@/components/ProductInfoAndPriceList/index.vue";
import BeanstandungsFormular from "@/components/ProductInfo/BeanstandungsFormular.vue";
import BeanstandungsFormularContent from "@/components/ProductInfo/Content/FormContent.vue";
import Preislisten from "@/components/ProductInfo/Preislisten/index.vue";
import PreislistenMain from "@/components/ProductInfo/Preislisten/List/Main.vue";
import PreislistenList from "@/components/ProductInfo/Preislisten/List/index.vue";
import PreislistenListContent from "@/components/ProductInfo/Preislisten/List/Content.vue";
import CalculatorHeader from "@/components/ProductInfo/Calculator/index.vue";
import CalculatorContent from "@/components/ProductInfo/Calculator/content.vue";
import Brochure from "@/components/ProductInfo/Brochures/index";
import BrochureData from "@/components/ProductInfo/Brochures/Content/Data.vue";

export default [
  {
    path: "/product-info",
    name: "productInfo",
    component: SubDefault,
    props: {
      content: ProductInfo,
      switchOrderMobile: true,
      data: ProductInfoContent,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
  },
  {
    path: "/product-info/product-brochures",
    name: "produktbroschuren",
    component: SubDefault,
    props: {
      content: Produktbroschuren,
      switchOrderMobile: true,
      data: ProduktbroschurenList,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
  },
  {
    path: "/product-info/product-brochures/bridgestone",
    name: "bridgestoneProduktbroschuren",
    component: SubDefault,
    props: {
      content: BridgestoneProduktbroschuren,
      switchOrderMobile: true,
      data: BridgestoneList,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
  },
  {
    path: "/product-info/product-brochures/firestone",
    name: "firestoneProduktbroschuren",
    component: SubDefault,
    props: {
      content: FirestoneProduktbroschuren,
      switchOrderMobile: true,
      data: FirestoneList,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
  },
  {
    path: "/product-info/product-brochures/technologies-markings-and-more",
    name: "technologien",
    component: SubDefault,
    props: {
      content: Technologien,
      switchOrderMobile: true,
      data: TechnologyList,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
  },
  {
    path: "/product-info/pricat",
    name: "pricat",
    component: SubDefault,
    props: {
      content: Pricat,
      switchOrderMobile: true,
      data: PricatList,
    },
    meta: {
      requiredLang: ["de", "ch"],
    },
  },
  {
    path: "/product-info/b-drive",
    name: "bDrive",
    component: SubDefault,
    props: {
      content: BDrive,
      switchOrderMobile: true,
      data: BdriveContent,
    },
    meta: {
      requiredLang: ["ch", "nl"],
    },
  },
  {
    path: "/product-info/complaint-form",
    name: "beanstandungsFormular",
    component: SubDefault,
    props: {
      content: BeanstandungsFormular,
      switchOrderMobile: true,
      data: BeanstandungsFormularContent,
    },
    meta: {
      requiredLang: ["de", "ch"],
    },
  },
  {
    path: "/product-info/price-list",
    name: "preislisten",
    component: SubDefault,
    props: {
      content: Preislisten,
      switchOrderMobile: true,
      data: PreislistenMain,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
  },
  {
    path: "/product-info/price-list/list",
    name: "preislistenList",
    component: SubDefault,
    props: {
      content: PreislistenList,
      switchOrderMobile: true,
      data: PreislistenListContent,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
  },
  {
    path: "/product-info/price-list/:slug",
    name: "pkw",
    component: SubDefault,
    props: {
      content: Pkw,
      switchOrderMobile: true,
      data: PkwList,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
    },
  },

  {
    path: "/product-information-and-price-lists",
    name: "productInfoAndPriceList",
    component: Default,
    props: {
      content: ProductInfoAndPriceList,
      switchOrderMobile: true,
    },
    meta: {
      requiredLang: ["de", "ch"],
    },
  },
  {
    path: "/product-info/fuel-calculator",
    name: "fuelCalculator",
    component: SubDefault,
    props: {
      content: CalculatorHeader,
      switchOrderMobile: true,
      data: CalculatorContent,
    },
    meta: {
      requiredLang: ["de", "ch"],
    },
  },

  {
    path: "/product-info/brochures-price-list",
    name: "brochure_prices",
    component: SubDefault,
    props: {
      content: Brochure,
      switchOrderMobile: true,
      data: BrochureData,
    },
    meta: {
      requiredLang: ["de", "ch", "nl"],
      permission: "mediaDownload.see",
    },
  },
];
