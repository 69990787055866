<template>
  <Loader :show="loadingFilters" v-if="loadingFilters"> </Loader>
  <div v-else>
    <MDBRow>
      <MDBCol col="5" offsetLg="7">
        <div class="d-flex flex-column justify-content-end">
          <div>
            <input v-model="searchText" type="text" class="form-control"
              :placeholder="$t('placeholders.searchMedia')" />
          </div>
          <span class="d-flex justify-content-end">
            <MDBBtn color="primary" @click="filter">{{
              $t("buttons.toSearch")
              }}</MDBBtn>
          </span>
        </div>
      </MDBCol>
    </MDBRow>

    <MDBRow class="pb-3 pt-3">
      <MDBCol col="12">
        {{ $t("setFilters") }}
      </MDBCol>
    </MDBRow>

    <MDBRow v-if="categories.length > 0">
      <MDBCol cols="12" lg="6" xl="2" class="mb-4" v-for="(data, i) in categories" :key="i">
        <div class="d-flex justify-content-start align-items-center pb-3">
          <span class="fw-bold">{{
            $t("brochureCategories." + data.name)
            }}</span>
        </div>

        <div>
          <div class="d-flex justify-content-start align-items-center" v-for="child in data.children" :key="child.id">
            <MDBSwitch @input="filterFiles(child.id, child.parent_id)" v-model="categorySelections[child.id]"
              :disabled="loading" />
            <span>{{ $t("brochureCategories." + child.name) }}</span>
          </div>
        </div>
      </MDBCol>
      <MDBCol cols="12"><span @click="clearFilters()" class="text-decoration-underline cursor--pointer">{{
        $t("buttons.clearFilters") }}
        </span></MDBCol>
    </MDBRow>

    <Loader :show="loading" v-if="loading"></Loader>
    <div v-else class="pt-5">
      <MDBRow class="pb-3">
        <MDBCol cols="12" lg="6"><span class="fw-bold">{{ $t("searchResults") }}:</span>
          {{ totalMediaFiles }}</MDBCol>
        <MDBCol cols="12" lg="6" class="d-flex justify-content-end"><span
            class="me-2 cursor--pointer text-decoration-underline" @click="selectAll()">{{ $t("buttons.selectAll")
            }}</span>
          <span @click="deselectAll()" class="cursor--pointer text-decoration-underline">{{ $t("buttons.deselectAll")
            }}</span>
        </MDBCol>
      </MDBRow>
      <MDBRow class="pb-3">
        <MDBCol cols="12" class="d-flex justify-content-end">
          <MDBBtn type="button" color="danger" @click="downloadAll()" :disabled="!selectedItemIds.length">{{
            $t("buttons.downloadSelection") }}</MDBBtn>
        </MDBCol>
      </MDBRow>
      <MDBRow class="pb-5">
        <MDBCol cols="12" lg="6">{{ $t("sortBy.choiceBy") }}:</MDBCol>
        <MDBCol cols="12" lg="6" class="d-flex justify-content-end">
          <span v-if="!isNetherland">{{ $t("sortBy.date") }}
            <img @click="sortData('created_at', 'desc')" :src="require('@/assets/img/icons/arrow_up_black.png')"
              alt="filter_btn" class="cursor--pointer" />
            <img @click="sortData('created_at', 'asc')" :src="require('@/assets/img/icons/arrow_down_black.png')"
              alt="filter_btn" class="cursor--pointer" />
          </span>
          <span>{{ $t("sortBy.name") }}
            <img @click="sortData('title', 'desc')" :src="require('@/assets/img/icons/arrow_up_black.png')"
              alt="filter_btn" class="cursor--pointer" />
            <img @click="sortData('title', 'asc')" :src="require('@/assets/img/icons/arrow_down_black.png')"
              alt="filter_btn" class="cursor--pointer" />
          </span>
        </MDBCol>
      </MDBRow>
      <MDBRow v-if="mediaFiles && mediaFiles.length > 0">
        <FileItemApi :file="media" @click="selectItems(media)" v-for="media in mediaFiles" :key="media.id"
          :selectedItemIds="selectedItemIds" :selectedItemOrigins="selectedItemOrigins" @uncheck="deselectAll" />
      </MDBRow>
      <MDBRow v-if="paginationLinks && mediaFiles && mediaFiles.length > 0">
        <MDBCol cols="12" class="d-flex justify-content-center">
          <MDBPagination>
            <MDBPageItem v-for="(paginationLink, index) in paginationLinks" :key="index"><span class="cursor--pointer"
                :class="`${!paginationLink?.active ? '' : 'text-decoration-underline'
                  }`" v-html="paginationLink?.label" @click="paginate(paginationLink?.url)"></span></MDBPageItem>
          </MDBPagination>
        </MDBCol>
      </MDBRow>
    </div>
  </div>
</template>

<script setup>
import FileItemApi from "@/components/ProductInfo/FileItemApi.vue";
import Loader from "@/components/General/Loader/index.vue";
import {
  MDBRow,
  MDBCol,
  MDBSwitch,
  MDBBtn,
  MDBPagination,
  MDBPageItem,
} from "mdb-vue-ui-kit";
import { onMounted, computed, ref } from "vue";

import config from "@/config";
import { useStore } from "vuex";

const store = useStore();
const LANG = localStorage.getItem("lang");
const isNetherland = computed(() => LANG === "nl");

const categories = computed(() => store.getters.getBrochureCategories);
const loading = computed(() => store.getters.getPending);
const mediaFiles = computed(() => store.getters.getFileBrochures);
const paginationLinks = computed(() => store.getters.getPaginationLinks);
const totalMediaFiles = computed(() => store.getters.getTotalMediaFiles);

const selectedItemIds = ref([]);
const selectedItemOrigins = ref([]);
const selectedCategories = ref([]);
const categorySelections = ref([]);
const loadingFilters = ref(true);
const searchText = ref(null);
const page = ref(1);
const sortField = ref("created_at");
const sortType = ref("desc");

const filterFiles = async (id, parentId) => {
  // If the ID already exists, remove it
  const data = `${id}_${parentId}`;
  const existingIndex = selectedCategories.value.indexOf(data);
  if (existingIndex !== -1) {
    selectedCategories.value.splice(existingIndex, 1);
  } else {
    selectedCategories.value.push(data);
  }

  await filter();
};

const sortData = async (field, type) => {
  sortField.value = field;
  sortType.value = type;

  await filter();
};

const filter = async () => {
  deselectAll();
  const data = {
    filters: selectedCategories.value,
    searchText: searchText.value,
    page: page.value,
    sortField: sortField.value,
    sortType: sortType.value,
    itemsPerPage: 9,
  };
  await store.dispatch("filterBrochureFiles", data);
};

const paginate = async (label) => {
  if (label === null) {
    return;
  }

  page.value = label.match(/\d*$/g)[0];

  await filter();
};

const selectItems = (media) => {
  const existingIndex = selectedItemIds.value.indexOf(
    media.id + "-" + media.origin
  );

  if (existingIndex !== -1) {
    selectedItemIds.value.splice(existingIndex, 1);
    selectedItemOrigins.value.splice(existingIndex, 1);
  } else {
    selectedItemIds.value.push(media.id + "-" + media.origin);
    selectedItemOrigins.value.push(media.origin);
  }
};

const downloadFile = () => {
  console.log(selectedItemOrigins.value);

  window.open(
    `${config.URL}/api/de/brochure/download/files?id=${selectedItemIds.value}&origin=${selectedItemOrigins.value}`
  );
};

const selectAll = () => {
  deselectAll();
  for (let media of mediaFiles.value) {
    selectItems(media);
  }
};

const downloadAll = () => {
  downloadFile(selectedItemIds.value, selectedItemOrigins.value);
};

const deselectAll = () => {
  selectedItemIds.value = [];
  selectedItemOrigins.value = [];
};

onMounted(async () => {
  await clearFilters();

  loadingFilters.value = false;
});

const clearFilters = async () => {
  loadingFilters.value = true;
  selectedItemIds.value = [];
  selectedCategories.value = [];
  page.value = 1;
  searchText.value = null;

  await store.dispatch("fetchBrochureCategories");

  for (let category of categories.value) {
    if (category.children.length > 0) {
      for (let child of category.children) {
        categorySelections.value[child.id] = false;
      }
    }
  }

  categorySelections.value = categorySelections.value.filter(
    (element) => element !== undefined
  );

  await filter();
  loadingFilters.value = false;
};
</script>

<style scoped lang="scss">
@import "@/styles/_variables.scss";
@import "@/styles/_forms.scss";
</style>
