<template>
<UserBankStatement/>
</template>

<script>
import UserBankStatement from "../components/BankStatement";
export default {
  name: "BankStatement",
  components: {
    UserBankStatement
  }
}
</script>

<style scoped>

</style>
