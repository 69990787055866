import ApiService from "./api.service";

const MessageService = {
  async loadMessages(data) {
    try {
      const queryString =
        "fieldName=" +
        data["orderParams"]["fieldName"] +
        "&" +
        "orderBy=" +
        data["orderParams"]["orderBy"];
      return await ApiService.get("threadsUsers?page=1&" + queryString);
    } catch (e) {
      console.error(e);
    }
  },

  async loadUnreadMessages() {
    try {
      return await ApiService.get("unread/threadsUsers");
    } catch (e) {
      console.error(e);
    }
  },

  async setMessageIsRead(id) {
    try {
      return await ApiService.put(`threadsUsers/${id}`, {
        is_read: true,
      });
    } catch (e) {
      console.error(e);
    }
  },

  async deleteMessages(ids) {
    try {
      var params = {
        params: {
          ids: ids,
        },
      };
      return await ApiService.delete("threadsUsers", params);
    } catch (e) {
      console.error(e);
    }
  },
};

export { MessageService };
