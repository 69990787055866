const TOKEN_KEY = "token";
const REFRESH_TOKEN_KEY = "refresh_token";
const USER_HASH_ID = "user_hashid";
const PERCENTAGE = "percentage";
const PERMISSIONS = "permissions";
const PRODUCT_PAGE = "product_page";
const PRODUCT_NAME = "product_name";
const POINTS_FROM = "points_from";
const POINTS_TO = "points_to";
const USER_ID = "user_id";
const MENU = "menu";
const FOOTER = "footer";
const SLIDERS = "sliders";
const MAIN_BOXES = "main_boxes";
const GROUPS = "groups";
const JOB_POSITIONS = "job_positions";

const TokenService = {
  getToken() {
    return localStorage.getItem(TOKEN_KEY);
  },

  saveToken(accessToken) {
    localStorage.setItem(TOKEN_KEY, accessToken);
  },

  removeToken() {
    localStorage.removeItem(TOKEN_KEY);
  },

  saveRefreshToken(refreshToken) {
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
  },

  removeRefreshToken() {
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  },

  saveUserHashId(hashid) {
    localStorage.setItem(USER_HASH_ID, hashid);
  },

  saveUserId(hashid) {
    sessionStorage.setItem(USER_ID, hashid);
  },

  getUserId() {
    return sessionStorage.getItem(USER_ID);
  },

  removeUserHashId() {
    localStorage.removeItem(USER_HASH_ID);
  },

  getUserHashId() {
    return localStorage.getItem(USER_HASH_ID);
  },

  saveProfilePercentage(data) {
    return sessionStorage.setItem(PERCENTAGE, data);
  },

  savePermissions(data) {
    return sessionStorage.setItem(PERMISSIONS, data);
  },

  getPermissions() {
    return sessionStorage.getItem(PERMISSIONS);
  },

  showElement(name) {
    let data = sessionStorage.getItem(PERMISSIONS);

    if (data) {
      const search = data.search(name);
      return search != -1;
    }
    return false;
  },

  setProductPage(item) {
    sessionStorage.setItem(PRODUCT_PAGE, item);

    return true;
  },

  setProductData(name, points_from, points_to) {
    sessionStorage.setItem(PRODUCT_NAME, name);
    sessionStorage.setItem(POINTS_FROM, points_from);
    sessionStorage.setItem(POINTS_TO, points_to);

    return true;
  },
  getProductPointsTo() {
    return sessionStorage.getItem(POINTS_TO);
  },
  getProductPointsFrom() {
    return sessionStorage.getItem(POINTS_FROM);
  },

  getProductName() {
    return sessionStorage.getItem(PRODUCT_NAME);
  },

  getProductPage() {
    return sessionStorage.getItem(PRODUCT_PAGE);
  },

  removeProductPage() {
    sessionStorage.removeItem(PRODUCT_PAGE);
  },
  removeProductName() {
    sessionStorage.removeItem(PRODUCT_NAME);
  },
  removeProductPointsFrom() {
    sessionStorage.removeItem(POINTS_FROM);
  },
  removeProductPointsTo() {
    sessionStorage.removeItem(POINTS_TO);
  },
  saveMenu(data) {
    sessionStorage.setItem(MENU, data);
  },
  getMenu() {
    return sessionStorage.getItem(MENU);
  },
  saveFooter(data) {
    sessionStorage.setItem(FOOTER, data);
  },
  getFooter() {
    return sessionStorage.getItem(FOOTER);
  },
  saveSliders(data) {
    sessionStorage.setItem(SLIDERS, data);
  },
  getSliders() {
    return sessionStorage.getItem(SLIDERS);
  },
  saveMainBoxes(data) {
    sessionStorage.setItem(MAIN_BOXES, data);
  },
  getMainBoxes() {
    return sessionStorage.getItem(MAIN_BOXES);
  },
  saveGroups(data) {
    sessionStorage.setItem(GROUPS, data);
  },
  getGroups() {
    return sessionStorage.getItem(GROUPS);
  },
  saveJobPositions(data) {
    sessionStorage.setItem(JOB_POSITIONS, data);
  },
  getJobPositions() {
    return sessionStorage.getItem(JOB_POSITIONS);
  },
};

export default TokenService;
