const state = {
  user: {},
  addresses: [],
  profile: {},
  bankStatement: [],
  lastBankStatement: null,
  categories: [],
  products: [],
  bestSoldProducts: [],
  seasonalProducts: [],
  soonAffordableProducts: [],
  productsMeta: {},
  productsPageNumber: 1,
  product: {},
  attribute_options: [],
  productRequestSent: false,
  categoriesRequestSent: false,
  jobPositions: [],
  trainings: [],
  training: {},
  questions: [],
  trainingResult: false,
  hasPoints: false,
  endQuizDate: null,
  btrainings: [],
  btraining: {},
  bTrainingDetails: [],
  bTrainingCosts: [],
  bTrainingTypes: [],
  bTrainingResult: [],
  addressSelected: null,
  userSlides: [],
  textPages: [],
  textPage: {},
  pickupLocations: [],
  watchedVideo: false,
  messages: [],
  isPending: false,
  toast: [],
  userOrders: [],
  userOrder: null,
  userPromotions: {},
  newsFeeds: [],
  currentNewsFeed: null,
  userProducts: [],
  wholesalers: [],
  cooperations: [],
  singleHunter: {},
  prices: [],
  priceCategories: [],
  singlePriceList: [],
  damageTypes: [],
  tyrePositions: [],
  vehicleTyres: [],
  tyreWears: [],
  salutations: [],
  pointsByCustomerNumber: [],
  tyrePositionChildren: [],
  investmentSupportNew: false,
  certificateTexts: [],
  profilePercentageNum: 0,
  menu: [],
  unreadMessages: 0,
  kickbackInfo: [],
  mediaCategories: [],
  singleMedia: [],
  mediaFiles: [],
  singleMediaCategory: {},
  allDealers: 0,
  wshSelect: [],
  inUnknownGroup: true,
  defaultBanners: [],
  permissions: [],
  paginationLinks: [],
  totalMediaFiles: 0,
  tyreLimit: 0,
  promotions: [],
  allWholesalers: [],
  brochureCategories: [],
  brochureFiles: [],
  cart: {},
  mainBoxes: [],
  fetchMenu: false,
  fetchLeftColumn: false,
  points_thankyou: 0,
  message_error: "",
  showConditionModal: false,
};

export { state };
