<template>
  <img :src="require(`@/assets/img/calculator.jpg`)" />
  <div class="calc-header text-uppercase mt-5">
    {{ $t("fuelCalculator.calcHeader") }}
  </div>
  <div class="form bg-white">
    <Calc v-if="!isCalculated" @calculated="calc($event)" />

    <div v-else class="bg-white">
      <Calc formClass="form bg-white px-2 px-xxl-5 py-xxl-4 mt-3" contentClass="bg-gray px-1 px-xxl-1 py-1"
        @calculated="calc($event)" :formValue="form" :recalculate="true" />

      <div class="px-5 py-4 mt-4">
        <MDBRow class="pt-3">
          <MDBCol col="12">
            <div class="green-header text-uppercase">
              {{ $t("fuelCalculator.results") }}
            </div>
            <div class="subheader">{{ $t("fuelCalculator.please") }}</div>
          </MDBCol>
          <MDBCol col="12" md="10">
            <MDBTable borderless class="table-dashboard">
              <tbody>
                <tr>
                  <td class="label text-uppercase">
                    {{ $t("fuelCalculator.table.euroSave") }}
                  </td>
                  <td class="label text-uppercase">
                    {{ $t("fuelCalculator.table.fuelSavePercent") }}
                  </td>
                  <td class="label text-uppercase">
                    {{ $t("fuelCalculator.table.newConsumption") }}
                  </td>
                </tr>
                <tr>
                  <td>
                    <span class="text-uppercase" :class="[
                      {
                        'green-header green-header--data': (savingInEur) => 0,
                      },
                      { 'red-header red-header--data': savingInEur < 0 },
                    ]">{{ savingInEur.toFixed(2) }}</span>&nbsp;
                    <span :class="[
                      {
                        'success-green': (savingInEur) => 0,
                      },
                      {
                        'text-danger': savingInEur < 0,
                      },
                    ]">EUR</span>
                  </td>
                  <td>
                    <span class="text-uppercase" :class="[
                      {
                        'green-header green-header--data': (
                          fuelPercentageDisplay
                        ) => 0,
                      },
                      {
                        'red-header red-header--data':
                          fuelPercentageDisplay < 0,
                      },
                    ]">{{ fuelPercentageDisplay }}</span>&nbsp;
                    <span :class="[
                      {
                        'success-green': (fuelPercentageDisplay) => 0,
                      },
                      {
                        'text-danger': fuelPercentageDisplay < 0,
                      },
                    ]">%</span>
                  </td>
                  <td>
                    <span class="text-uppercase" :class="[
                      {
                        'green-header green-header--data':
                          newFuelConsumption < form.fuel_consumption,
                      },
                      {
                        'red-header red-header--data':
                          newFuelConsumption > form.fuel_consumption,
                      },
                    ]">{{ newFuelConsumption.toFixed(2) }} </span>&nbsp;
                    <span :class="[
                      {
                        'success-green':
                          newFuelConsumption < form.fuel_consumption,
                      },
                      {
                        'text-danger':
                          newFuelConsumption > form.fuel_consumption,
                      },
                    ]">Liter</span>
                  </td>
                </tr>
                <tr>
                  <td class="label text-uppercase">
                    {{ $t("fuelCalculator.table.fuelSaveLiter") }}
                  </td>
                  <td class="label text-uppercase">
                    {{ $t("fuelCalculator.table.fuelSaveTotalLiter") }}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <span class="text-uppercase" :class="[
                      {
                        'green-header green-header--data': (savedInLiter) =>
                          0,
                      },
                      {
                        'red-header red-header--data': savedInLiter < 0,
                      },
                    ]">{{ savedInLiter.toFixed(2) }}</span>&nbsp;
                    <span :class="[
                      {
                        'success-green': (savedInLiter) => 0,
                      },
                      {
                        'text-danger': savedInLiter < 0,
                      },
                    ]">Liter</span>
                  </td>
                  <td>
                    <span class="green-header green-header--data text-uppercase" :class="[
                      {
                        'green-header green-header--data': (fuelSavedTotal) =>
                          0,
                      },
                      {
                        'red-header red-header--data': fuelSavedTotal < 0,
                      },
                    ]">{{ fuelSavedTotal.toFixed(0) }}</span>&nbsp;
                    <span :class="[
                      {
                        'success-green': (fuelSavedTotal) => 0,
                      },
                      {
                        'text-danger': fuelSavedTotal < 0,
                      },
                    ]">Liter</span>
                  </td>
                </tr>
              </tbody>
            </MDBTable>
          </MDBCol>
        </MDBRow>

        <div class="mb-4">
          <small class="fw-bold">{{ $t("fuelCalculator.description") }}</small>
        </div>

        <h6 @click="displayNotice" class="text-decoration-underline cursor--pointer">
          {{ $t("legalNotice") }}
        </h6>
        <small v-html="$t('fuelCalculator.noticeText')" v-if="showNotice"></small>
      </div>
    </div>
  </div>
</template>

<script setup>
import Calc from "@/components/ProductInfo/Calculator/components/calc.vue";
import { MDBRow, MDBCol, MDBTable } from "mdb-vue-ui-kit";
import { ref } from "vue";

const form = ref({
  old_label: null,
  tyre_life: null,
  fuel_price: null,
  new_label: null,
  fuel_consumption: null,
});
const isCalculated = ref(false);
const fuelPercentageDisplay = ref(0);
const newFuelConsumption = ref(0);
const savingInEur = ref(0);
const savedInLiter = ref(0);
const fuelSavedTotal = ref(0);
const showNotice = ref(true);

const displayNotice = () => {
  showNotice.value = !showNotice.value;
};

const calc = (event) => {
  fuelPercentageDisplay.value = event.fuelPercentageDisplay;
  fuelSavedTotal.value = event.fuelSavedTotal;
  newFuelConsumption.value = event.newFuelConsumption;
  savedInLiter.value = event.savedInLiter;
  savingInEur.value = event.savingInEur;

  form.value.old_label = event.form.old_label;
  form.value.tyre_life = event.form.tyre_life;
  form.value.fuel_price = event.form.fuel_price;
  form.value.new_label = event.form.new_label;
  form.value.fuel_consumption = event.form.fuel_consumption;

  isCalculated.value = true;
};
</script>

<style lang="scss" scoped>
@import "@/styles/_variables.scss";
@import "@/styles/mailbox.scss";

.table-dashboard {
  width: 100%;
  border-spacing: 1px;
  border-collapse: separate;
  padding-top: 30px;

  td,
  th {
    padding: 5px;
    text-align: left;
  }

  th {
    background: $gray;
    color: $backgroundGray;
  }
}

.table-dashboard>tbody>tr:nth-of-type(2n + 2)>* {
  background-color: $secondaryGray;
}

.calc-header {
  font-size: 16px;
  font-weight: bold;
  font-family: "BridgestoneType", Arial, sans-serif;
}
</style>
