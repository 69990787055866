<template>
  <MDBCol lg="4" md="6" class="mb-4">
    <a
      class="file btn btn-dark d-flex align-items-center justify-content-stretch position-relative w-100 p-4"
    >
      <MDBIcon
        :icon="`file-${setFileType(props.file.type ?? props.file.extension)}`"
        class="icon-extension d-flex align-items-center justify-content-center"
      />
      <MDBRow class="align-items-center text-start w-100">
        <MDBCol col="3" class="text-center">
          <MDBIcon
            icon="download"
            class="icon d-inline-flex align-items-center justify-content-center"
            :class="{
              'icon-extension--selected': isItemSelected(
                props.file.id,
                props.file.origin
              ),
            }"
            @click="dowloadSingleFile(props.file.id, props.file.origin)"
          />
        </MDBCol>
        <MDBCol col="7">
          <h6
            v-html="props.file.name ?? props.file.title"
            class="file-title text-white"
          ></h6>
          <p
            v-if="props.file.description"
            v-html="props.file.description"
            class="fw-bold mb-0"
          ></p>
          <p v-html="props.file.date" class="fw-bold mb-0"></p>
        </MDBCol>
      </MDBRow>
    </a>
  </MDBCol>
</template>

<script setup>
import { MDBRow, MDBCol, MDBIcon } from "mdb-vue-ui-kit";
import { defineProps, defineEmits } from "vue";
const emit = defineEmits(["uncheck"]);
import config from "@/config";

const props = defineProps({
  file: Object,
  selectedItemIds: Array,
  selectedItemOrigins: Array,
});

const setFileType = (type) => {
  if (type == "xlsx") {
    return "excel";
  } else {
    return type;
  }
};

const isItemSelected = (id, origin) => {
  const existingIndex = props.selectedItemIds.indexOf(id + "-" + origin);
  return existingIndex !== -1;
};

const downloadFile = (id, origin) => {
  const data = id + "-" + origin ?? props.selectedItemIds;
  const data_origin = origin ?? props.selectedItemOrigins;
  window.open(
    `${config.URL}/api/de/brochure/download/files?id=${data}&origin=${data_origin}`
  );
};

const dowloadSingleFile = (id, origin) => {
  downloadFile(id, origin);
  emit("uncheck");
};
</script>

<style scoped lang="scss">
@import "../../styles/_variables.scss";

.icon {
  font-size: 20px;
  background: white;
  color: $darkGray;
  border-radius: 100px;
  height: 40px;
  width: 40px;
  transform: scale(1);
  transition: 150ms ease-in-out;
}

.icon-extension {
  display: none;
  font-size: 20px;
  background: white;
  color: $darkGray;
  height: 40px;
  width: 80px;
  position: absolute;
  top: 0;
  right: 0;

  @media screen and (max-width: $xxxlBreakpoint) and (min-width: ($xlBreakpoint + 1)) {
    height: 30px;
    width: 60px;
    font-size: 17px;
  }

  @media screen and (max-width: $xlBreakpoint) and (min-width: $lgBreakpoint) {
    height: 20px;
    width: 40px;
    font-size: 12px;
  }

  &--selected {
    background: darken($dangerRed, 5%);
    color: $backgroundWhite;
  }
}

.file {
  min-height: 150px;
  height: 100%;
  background-color: $darkGray;
  z-index: 9;

  &:hover {
    background: darken($darkGray, 5%);

    .icon {
      transform: scale(1.1);
      color: $dangerRed;
    }
  }
}
</style>
