<template>
  <MDBCard class="with-category border-0">
    <router-link :to="{
      name: 'productDetails', params: {
        id: props.product && props.product.id ? props.product.id : 0
      }
    }
      ">
      <MDBCardBody>
        <div v-if="props.product && props.product.category" class="category">
          {{ props.product.category }}
        </div>
        <div v-if="isVariant" class="variant-info text-center">{{ $t('shop.variant') }} </div>

        <MDBCardText>
          <template v-if="empty">
            {{ $t("shop.noProducts") }}
          </template>
          <template v-else>
            <div :class="`product-image d-flex align-items-center justify-content-center`">
              <img :src="productImgSrc" class="" alt="product image" />
            </div>

            <p class="font-16 index-order-top" :class="affordable ? 'text-orange' : 'success-green'">
              {{ $t("shop.forPoints", { points: productPoints }) }}
            </p>

            <h6 class="fw-medium text-dark index-order-top" v-html="props.product.name"></h6>

            <p class="font-12 availability" :class="affordable ? 'text-orange' : 'success-green'">
              <template v-if="affordable">
                {{ $t("shop.remainingPoints", { points: remainingPoints }) }}
              </template>
              <template v-else>
                {{ $t("shop.reachedPoints") }}
              </template>
            </p>
          </template>
        </MDBCardText>
      </MDBCardBody>
    </router-link>
  </MDBCard>
</template>

<script setup>
import { MDBCard, MDBCardBody, MDBCardText } from "mdb-vue-ui-kit";
import { useStore } from "vuex";
import { defineProps, computed } from "vue";

const props = defineProps({
  product: Object,
  empty: {
    type: Boolean,
    default: false,
  },
});

const product = computed(() => {
  if (props.product.type == "variant" && props.product.variants) {
    let defaultVariant = props.product.variants.find(
      (item) => item.is_default == true
    );
    return defaultVariant;
  } else {
    return props.product;
  }
});

const isVariant = computed(() => {
  return props.product.type == "variant" && props.product.variants.length > 1
})

const productImgSrc = computed(() => {
  return props.product.type == "variant"
    ? product.value?.image
    : props.product.imageUrl;
});

const productPoints = computed(() => {
  return props.product.type == "variant"
    ? product.value?.points
    : props.product.points;
});

const store = useStore();
const user = computed(() => store.getters.getMe);

const affordable = computed(() => {
  return remainingPoints.value > 0;
});

const remainingPoints = computed(() => {
  return productPoints.value - user.value.points;
});
</script>

<style scoped lang="scss">
@import "../../styles/variables";
@import "../../styles/product_list_item";
</style>
