<template>
  <MDBRow>
    <FileItem v-for="(file, i) in files" :key="i" :file="file" />
  </MDBRow>
</template>

<script setup>
import FileItem from "@/components/ProductInfo/FileItem.vue";
import { MDBRow } from "mdb-vue-ui-kit";
import { computed } from "vue";
import i18n from "@/plugins/i18n";

const LANG = localStorage.getItem("lang");
const currentLocale = localStorage.getItem("currentLocale");
const isGermany = computed(() => LANG === "de");
const isNetherlands = computed(() => LANG === "nl");

const files = computed(() => {
  if (isGermany.value) {
    return [
      {
        file: "FS Vanhawk Multiseason_2021_2pager_Ansicht.pdf",
        name: i18n.global.t("fireStoneProducts.files.summer.title"),
        description: i18n.global.t(
          "fireStoneProducts.files.summer.description"
        ),
        date: i18n.global.t("fireStoneProducts.files.summer.date"),
        type: "pdf",
        simpleLink: true,
        directory: "broschuren/firestone/",
      },
      {
        file: "FS Winterhawk 4_2020_2pager_Ansicht.pdf",
        name: i18n.global.t("fireStoneProducts.files.winter.title"),
        description: i18n.global.t(
          "fireStoneProducts.files.winter.description"
        ),
        date: i18n.global.t("fireStoneProducts.files.winter.date"),
        type: "pdf",
        simpleLink: true,
        directory: "broschuren/firestone/",
      },
    ];
  } else if (isNetherlands.value) {
    return [
      {
        file: "Firestone Roadhawk2_Sales tool.pdf",
        name: "Firestone",
        description: "Roadhawk2_Sales tool",
        date: null,
        type: "pdf",
        simpleLink: true,
        directory: "broches_nl/",
      },
    ];
  } else {
    const data = {
      ch: [
        {
          file: "FS_TBR_Vanhawk_Multiseason_CH_de.pdf",
          name: "FS_TBR_Vanhawk",
          description: "Multiseason_CH_de",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "FS_TBR_Technischer Ratgeber_2024_DE.pdf",
          name: "FS_TBR_Technischer",
          description: "Ratgeber_2024_DE",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "FS_TBR_Range Brochure_2024_BSDL_LR.pdf",
          name: "FS_TBR_Range",
          description: "Brochure_2024_BSDL_LR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "FS_TBR_Product Brochure_A4_New Regionals_UPDATE_BSDL.pdf",
          name: "FS_TBR_Product",
          description: "Brochure_A4_New Regionals_UPDATE_BSDL",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "FS_TBR_Gesamtprogramm_2024_DE.pdf",
          name: "FS_TBR",
          description: "Gesamtprogramm_2024_DE",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "FS_TBR_Databook_2024_BSDL_LR.pdf",
          name: "FS_TBR",
          description: "Databook 2024_BSDL_LR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "FS_AS_ Gesamtbroschüre.pdf",
          name: "FS_AS_",
          description: "Gesamtbroschüre",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
        {
          file: "FS OTR MULTI BLOCK T_Infosheet.pdf",
          name: "FS OTR MULTI",
          description: "BLOCK T_Infosheet",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_ch/",
        },
      ],
      fr: [
        {
          file: "FS_S24_RangeBrochure_Short_SZFR.pdf",
          name: "FS_S24_RangeBrochure",
          description: "Short_SZFR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_fr/",
        },
        {
          file: "FS_TBR_Databook_2024_BSSZ-fr_LR.pdf",
          name: "FS_TBR_Databook",
          description: "2024_BSSZ-fr_LR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_fr/",
        },
        {
          file: "FS_TBR_Product Brochure_A4_New Regionals_BSSZ-fr_v01.pdf",
          name: "FS_TBR_Product",
          description: "Brochure_A4_New Regionals_BSSZ-fr_v01",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_fr/",
        },
        {
          file: "FS_TBR_Vanhawk_Multiseason_CH_fr.pdf",
          name: "FS_TBR_Vanhawk",
          description: "Multiseason_CH_fr",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_fr/",
        },
      ],
      it: [
        {
          file: "FS_S24_RangeBrochure_Short_SZIT.pdf",
          name: "FS_S24_RangeBrochure",
          description: "Short_SZFR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_it/",
        },
        {
          file: "FS_TBR_Databook_2024_BSIT_LR.pdf",
          name: "FS_TBR_Databook",
          description: "2024_BSIT_LR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_it/",
        },
        {
          file: "FS_TBR_Product Brochure_A4_New Regionals_UPDATE_BSIT.pdf",
          name: "FS_TBR_Product",
          description: "Brochure_A4_New Regionals_UPDATE_BSIT",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_it/",
        },
        {
          file: "FS_TBR_Range Brochure_2024_BSIT_LR.pdf",
          name: "FS_TBR_Range",
          description: "Brochure_2024_BSIT_LR",
          date: null,
          type: "pdf",
          simpleLink: true,
          directory: "brochures_it/",
        },
      ],
    };

    return data[currentLocale];
  }
});
</script>
